.sueCard {
  // width: 100%;
  // height: 650px;
  padding: 0px;
  background-size: cover;
  &__title {
    color: #eb646f;
    margin-left: 20px;
  }
  &__subtitle {
    font-weight: normal;
    margin-left: 20px;
  }
  &__description {
    font-weight: lighter;
    font-size: medium;
    // text-align: left;
  }
}

.docCard {
  padding-left: 20px;
  background-size: cover;
  color: white;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 5px;
  text-align: left;
  &__title {
    font-weight: 700;
  }
  &__subtitle {
    font-weight: normal;
  }
  &__description {
    padding-top: 30px;
    font-weight: normal;
    font-size: larger;
    letter-spacing: 0px;
    line-height: normal;
  }
}

.stepsDescriptionCard {
  background-size: cover;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 5px;
  text-align: center;
  margin-bottom: 100px;
  &__title {
    font-weight: 700;
  }
  &__subtitle {
    font-weight: normal;
  }
  &__description {
    padding-top: 30px;
    font-weight: normal;
    font-size: larger;
    letter-spacing: 0px;
    line-height: normal;
  }
  &__main-content {
    text-align: justify;
    line-height: 25px;
  }
}
