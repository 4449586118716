.stepNumberContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.stepCircle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  border:solid white 4px;
  width: 40px;
  height: 40px;
  margin: 1px;
  &__number {
    color: white;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    margin-bottom: 1px;
  }
}

.nextStepButton {
  &__red {
    color: #EB646F;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__green {
    color: #00AC9E;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__white {
    color: white;
  }
  &__yellow {
    color: #FAB816;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__purple {
    color: #4E4B99;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
}

.previousStepbutton {
  border: none;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  height: 35px;
  width: 140px;
  background-color: transparent;
}

.stepsContainer {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  margin-top: 20px;
}

#circle {
  background: transparent;
  border-radius: 50%;
  border:solid white 1px;
  width: 10px;
  height: 10px;
  margin: 1px;
}
#circleSelected {
  background: transparent;
  border-radius: 50%;
  border:solid white 3px;
  width: 10px;
  height: 10px;
  margin: 1px;
}

