.defaultNavbar {
  height: 170px;
  background-color: none;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 5px;
  &__link {
    padding-right: 70px;
    padding-left: 35px;
  }
  &__logo {
    width: 150px;
    height: 40px;
  }
}

.dropdown-menu.show {
  color: 'white';
  background-color: transparent;
  border: none;
}

.nav-item .nav-link {
  color: black;
  font-family: 'Raleway', 'sans-serif';
  letter-spacing: 3px;
  font-weight: 450;
  font-size: 15px;
  margin: 0 10px;
  &__black {
    color: black;
  }
}

.dropdown-item-custom,
.dropdown-item {
  color: black !important;
  font-family: 'Raleway', 'sans-serif';
  letter-spacing: 3px;
  font-weight: 450;
  font-size: 15px;
}

@media (min-width: 768px) {
  .nav-item-medium .nav-link {
    color: white;
    margin-right: 40px;
    font-family: 'Raleway', 'sans-serif';
    letter-spacing: 3px;
    font-weight: 450;
    font-size: 15px;
    &__black {
      color: black;
    }
  }

  .dropdown-item-medium {
    color: white !important;
    font-family: 'Raleway', 'sans-serif';
    letter-spacing: 3px;
    font-weight: 450;
    font-size: 15px;
  }
}
