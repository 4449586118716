.feedback-modal-sucess {
  background-color: #00AC9E;
  border-radius: 25px;
}

div.modal-content {
  border-radius: 30px;
}

.feedback-modal-title-success {
  color: #00AC9E;
  font-family: 'Raleway', sans-serif;
  &__text {
    padding-top: 1rem;
    margin-bottom: 0px;
    font-weight: 800;
    letter-spacing: 5px;
  }
}

.feedback-modal-failed {
  background-color: #EB646F;
  border-radius: 25px;
}

.feedback-modal-title-failed {
  color: #EB646F;
  font-family: 'Raleway', sans-serif;
  &__text {
    padding-top: 1rem;
    margin-bottom: 0px;
    font-weight: 800;
    letter-spacing: 5px;
  }
}