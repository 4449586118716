.habeas-data-modal {
  background-color: #eb646f;
  border-radius: 25px;
  &__title {
    color: black;
    font-family: 'Raleway', sans-serif;
    padding-top: 1rem;
    margin-bottom: 0px;
    font-weight: 800;
    letter-spacing: 5px;
  }
}
