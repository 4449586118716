.iconBorder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  border: solid #eb646f 4px;
  width: 40px;
  height: 40px;
  margin: 1px;
  &__icon {
    color: #eb646f;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    margin-bottom: 1px;
  }
}

.iconBorder-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  border: solid white 4px;
  width: 40px;
  height: 40px;
  margin: 1px;
  &__icon {
    color: white;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    margin-bottom: 1px;
  }
}
