.formTitle {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
}

.form-label {
  margin-right: 10px;
  color: white;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 3px;
  font-weight: 600;
  font-size: 14px;
}

.outlined-label {
  padding: 10px;
  margin-right: 10px;
  color: white;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 3px;
  font-weight: 600;
  font-size: 14px;
  border: solid 1px white;
  border-radius: 25px;
}

.form-labelStepOne {
  margin-right: 10px;
  color: white;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 3px;
  font-weight: 600;
  font-size: 14px;
  margin-top: 15px;
}

.form-select-basic {
  border-radius: 25px;
  border: 2px solid white;
  background-color: #eb646f;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  height: 55px;
  width: 470px;
  margin-left: 13px;
  padding-left: 30px;
  border-color: aliceblue;
}

.form-select-basic:focus,
.form-select-basic:hover {
  border-radius: 25px;
  border: 2px solid white;
  outline: none;
  background-color: #eb646f;
  border: 2px solid white;
}

.form-select-basic option {
  font-family: 'Raleway', sans-serif;
}

.form-select-basic {
  border-radius: 25px;
  border: 2px solid white;
  background-color: #eb646f;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  height: 55px;
  width: 470px;
  margin-left: 13px;
  padding-left: 30px;
  border-color: aliceblue;
}

.form-select-basic:focus,
.form-select-basic:hover {
  border-radius: 25px;
  border: 2px solid white;
  outline: none;
  background-color: #eb646f;
  border: 2px solid white;
}

.form-select-basic option {
  font-family: 'Raleway', sans-serif;
}

.form-input {
  border-radius: 25px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  height: 55px;
  width: 400px;
  margin-left: 13px;
  padding-left: 30px;
}

.form-input:focus,
.form-textArea:focus {
  outline-width: 0;
}

.form-input::-webkit-input-placeholder,
.form-textArea::-webkit-input-placeholder {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  margin-left: 30px;
}

.form-input::-webkit-input-placeholder,
.form-textArea::-webkit-input-placeholder {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  margin-left: 30px;
  // padding-left: 30px;
}

.form-input:-ms-input-placeholder,
.form-textArea:-ms-input-placeholder {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  margin-left: 30px;
}

.form-input:-moz-placeholder,
.form-textArea:-moz-placeholder {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  margin-left: 30px;
}

.form-input::-moz-placeholder,
.form-textArea::-moz-placeholder {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  margin-left: 30px;
}

.helperText {
  font-family: 'Raleway', sans-serif;
  text-align: 'left';
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 1px;
  color: white;
}

.form-textArea {
  border-radius: 25px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  height: 250px;
  width: 100%;
  margin-left: 13px;
  padding: 30px;
}
