table.roundedCorners {
  border: 1px solid DarkOrange;
  border-radius: 50px;
}

table.roundedCorners td, 
table.roundedCorners th { 
  border-bottom: 1px solid DarkOrange;
  padding: 10px; 
  }
table.roundedCorners tr:last-child > td {
  border-bottom: none;
}