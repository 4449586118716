.mainCardButton {
  background-color: #fab816;
  border: 1px solid #fab816;
  color: #f3f3f3;
  border-radius: 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
}

.sueCardButton {
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 25px;
  // &___misleadingAdver {
  //   color: #fab816;
  // }
  // &__lackInfo {
  //   color:
  // }
}

.outlinedButton {
  border: solid 1px white;
  border-radius: 25px;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  font-size: 12px;
  letter-spacing: 2px;
  height: 35px;
  width: 140px;
  background-color: transparent;
  &__red {
    border: solid 2px #eb646f;
    border-radius: 25px;
    color: #eb646f;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    height: 35px;
    width: 340px;
    background-color: transparent;
  }
}

.containerButton {
  &__black {
    color: black;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__red {
    color: #eb646f;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__green {
    color: #00ac9e;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__white {
    color: white;
  }
  &__yellow {
    color: #fab816;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__purple {
    color: #4e4b99;
    border: none;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    height: 35px;
    width: 140px;
    background-color: white;
  }
  &__size-m {
    width: 280;
    &__yellow {
      color: #fab816;
      border: none;
      border-radius: 25px;
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      letter-spacing: 2px;
      height: 35px;
      background-color: white;
    }
    &__red {
      color: #eb646f;
      border: none;
      border-radius: 25px;
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      letter-spacing: 2px;
      height: 35px;
      background-color: white;
    }
    &__purple {
      color: #4e4b99;
      border: none;
      border-radius: 25px;
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      letter-spacing: 2px;
      height: 35px;
      background-color: white;
    }
    &__green {
      color: #00ac9e;
      border: none;
      border-radius: 25px;
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      letter-spacing: 2px;
      height: 35px;
      background-color: white;
    }
  }
}
