.preventModal {
  background-color: #EB646F;
  border-radius: 25px;
}

div.modal-content {
  border-radius: 30px;
}

.preventModalTitle {
  color: #EB646F;
  font-family: 'Raleway', sans-serif;
  &__text {
    padding-top: 1rem;
    margin-bottom: 0px;
    font-weight: 800;
    letter-spacing: 5px;
  }
}